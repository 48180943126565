import axios from './axios'
import {CommonException} from "@/plugins/common";

/**
 * @param method
 * @param url
 * @param data
 * @param async
 * @returns {Promise<unknown>}
 */
export function request (method, url, data = []) {
    return new Promise((resolve, reject) => {
        let options = {method, url}

        if(["GET", "DELETE"].indexOf(method.toUpperCase()) > -1){
            options.params = data
        }else{
            options.data = data
        }

        // 请求
        return axios.request(options).then((rsp) => {
            if (rsp.err_code > 0) {
                throw new CommonException(rsp.err_msg, rsp.err_code);
            }

            data = rsp.meta ? {meta: rsp.meta, data: rsp.data} : rsp.data

            resolve(data)
        }).catch(e => {
            reject(e)
        })
    })
}


/**
 * post请求
 *
 * @param url
 * @param data
 * @returns {Promise<*>}
 */
export function httpPost (url, data = []) {
    return request('POST', url, data);
}

/**
 * get请求
 *
 * @param url
 * @param query
 * @returns {Promise<*>}
 */
export function httpGet (url, query = []) {
    return request('GET', url, query);
}


/**
 * put请求
 *
 * @param url
 * @param data
 * @returns {Promise<*>}
 */
export function httpPut (url, data = []) {
    return request('PUT', url, data);
}


/**
 * put请求
 *
 * @param url
 * @param query
 * @returns {Promise<*>}
 */
export function httpDelete (url, query = []) {
    return request('DELETE', url, query);
}
